import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['activity', 'integration']

  connect() {
    this.switch();
  }

  switch() {
    this.integrationTarget.style.display =
      this.activityTarget.checked ? 'block' : 'none';
  }
}
